import { ReactElement, useMemo } from "react";

function Table(props: {
  items: Array<any>;
  cols: Array<{
    key: string;
    col?: number;
    format?: (val: string | number) => string | ReactElement;
    className?: string;
  }>;
  titles?: { [key: string]: string };
  header: string | ReactElement;
  rank?: boolean;
  rankCol?: number;
  classNameHeader?: string;
}) {
  const hasRank = useMemo(() => props.rank ?? true, [props.rank]);
  return (
    <div className="flex-fill rank-table">
      <div className={`rank-thead ${props.classNameHeader ?? ""}`}>
        {props.header}
      </div>
      <div className="rank-tbody">
        <div className={`row g-0 text-center${props.titles ? "" : " d-none"}`}>
          {hasRank ? (
            <div key="rank" className={`col-${props.rankCol ?? 1}`}></div>
          ) : null}
          {props.cols.map((col) => (
            <div
              key={col.key}
              className={
                `${col.className ?? ""} ` + (col.col ? `col-${col.col}` : "col")
              }
            >
              {props.titles?.[col.key]}
            </div>
          ))}
        </div>
        {props.items.map((item, index) => {
          const rank = index + 1;
          return (
            <div key={rank} className="rank-list row g-0">
              {hasRank ? (
                <div
                  key="rank"
                  className={`col-${props.rankCol ?? 1} justify-content-center`}
                >
                  {rank > 3 ? (
                    <div
                      className="d-flex rounded-circle align-items-center justify-content-center"
                      style={{
                        background: "#000000B2",
                        width: "1.1em",
                        height: "1.1em",
                        fontSize: "1em",
                      }}
                    >
                      {rank}
                    </div>
                  ) : (
                    <img
                      alt={"" + rank}
                      src={`/assets/rank${rank}.png?v=1`}
                      width="80%"
                      height="auto"
                      loading="eager"
                      style={{
                        objectFit: "contain",
                        objectPosition: "center",
                        width: "1.5em",
                        height: "1.5em",
                      }}
                    />
                  )}
                </div>
              ) : null}
              {props.cols.map((col) => (
                <div
                  key={col.key}
                  className={
                    `${col.className ?? ""} ` +
                    (col.col ? `col-${col.col}` : "col")
                  }
                >
                  {col.format ? col.format(item[col.key]) : item[col.key]}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Table;
