import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getDocs, collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { Group } from "../types";
import { AppDispatch } from ".";

// Initial State
interface GroupState {
  groups: Group[];
  loading: boolean;
  error: string | null;
}

const initialState: GroupState = {
  groups: [],
  loading: false,
  error: null,
};

// Async Thunks fetch all groups
export const fetchAllGroups = createAsyncThunk(
  "groups/fetchAllGroups",
  async (_, { rejectWithValue }) => {
    try {
      const querySnapshot = await getDocs(collection(db, "groups"));
      const groups: Group[] = [];
      querySnapshot.forEach((doc) => {
        const groupData = doc.data();
        if (!groupData.isDeleted) {
          groups.push({ id: doc.id, ...groupData } as Group);
        }
      });
      return groups;
    } catch (error) {
      return rejectWithValue("Failed to fetch groups");
    }
  }
);

export const subscribeToGroups = () => (dispatch: AppDispatch) => {
  const groupsRef = collection(db, "groups");

  // Subscribe to changes
  const unsubscribe = onSnapshot(groupsRef, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const group = { id: change.doc.id, ...change.doc.data() } as Group as any;

      if (change.type === "added" && !group.isDeleted) {
        dispatch(addGroup(group));
      } else if (change.type === "modified" && !group.isDeleted) {
        dispatch(updateGroup(group));
      } else if (change.type === "removed" || group.isDeleted) {
        dispatch(removeGroup(group.id));
      }
    });
  });

  return unsubscribe; // Return the unsubscribe function for cleanup
};

// Group Slice
const groupSlice = createSlice({
  name: "groups",
  initialState,
  selectors: {
    selectGroups: (state) => state.groups,
    selectGroupById: (state, groupId: string) =>
      state.groups.find((g) => g.id === groupId),
    selectGroupByRound: (state, round: string) =>
      state.groups.filter((g) => g.round === round),
  },
  reducers: {
    clearState: (state) => {
      state.groups = [];
      state.loading = false;
      state.error = null;
    },
    addGroup(state, action: PayloadAction<Group>) {
      state.groups.push(action.payload);
    },
    updateGroup(state, action: PayloadAction<Group>) {
      const index = state.groups.findIndex((g) => g.id === action.payload.id);
      if (index !== -1) {
        state.groups[index] = action.payload;
      }
    },
    removeGroup(state, action: PayloadAction<string>) {
      state.groups = state.groups.filter((g) => g.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    // Fetch All Groups
    builder.addCase(fetchAllGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchAllGroups.fulfilled,
      (state, action: PayloadAction<Group[]>) => {
        state.loading = false;
        state.groups = action.payload;
      }
    );
    builder.addCase(fetchAllGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default groupSlice.reducer;

export const { clearState, addGroup, updateGroup, removeGroup } =
  groupSlice.actions;

export const { selectGroups, selectGroupById, selectGroupByRound } =
  groupSlice.selectors;
