import { map } from "lodash";
import { Group, Player, UserBetStatByShoe } from "../types";

export const makeFixturesData = (
  groupsFirst: Group[],
  playersMapping: { [key: string]: Player },
  statByPlayer: {
    [key: string]: UserBetStatByShoe[];
  },
  sortBy?: string
) => {
  return map(groupsFirst, (group: Group) => {
    const uu = map(group.players, (playerId) => {
      const player = playersMapping[playerId];
      const stats = statByPlayer[playerId];
      const point = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return acc + stat.point;
          }, 0)
        : 0;
      const shoe = stats ? stats.length : 0;
      const chip = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return acc + stat.totalBalance;
          }, 0)
        : 0;
      return {
        name: player?.nickname,
        point,
        shoe,
        chip,
      };
    });

    if (uu.length > 0) {
      if (!sortBy) {
        uu.sort((a, b) => {
          if (a.point === b.point) {
            return b.chip - a.chip;
          }
          return b.point - a.point;
        });
      }

      switch (sortBy) {
      }
    }

    return {
      name: group.name,
      users: uu,
    };
  });
};
