import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { Player } from "../types";
import { AppDispatch } from ".";

interface PlayerState {
  players: Player[];
  loading: boolean;
  error: string | null;
}

// Initial State
const initialState: PlayerState = {
  players: [],
  loading: false,
  error: null,
};

// Async Thunk to Fetch Players from Firestore
export const fetchPlayers = createAsyncThunk(
  "players/fetchPlayers",
  async (_, { rejectWithValue }) => {
    try {
      const playersRef = collection(db, "players");
      const q = query(playersRef, where("isDeleted", "==", false));
      const querySnapshot = await getDocs(q);
      const players: Player[] = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() } as Player);
      });

      players.sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1));
      return players;
    } catch (error) {
      return rejectWithValue("Failed to fetch players");
    }
  }
);

export const subscribeToPlayers = () => (dispatch: AppDispatch) => {
  const playersRef = collection(db, "players");
  const q = query(playersRef);

  // Subscribe to real-time changes
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const player = { id: change.doc.id, ...change.doc.data() } as Player;

      if (change.type === "added") {
        dispatch(addPlayer(player));
      } else if (change.type === "modified") {
        dispatch(updatePlayer(player));
      } else if (change.type === "removed") {
        if (player.id) {
          dispatch(removePlayer(player.id));
        }
      }
    });
  });

  return unsubscribe; // Return the unsubscribe function
};

// Player Slice
const playerSlice = createSlice({
  name: "players",
  initialState,
  selectors: {
    getPlayers: (state) => state.players,
    getPlayerById: (state, id: string) =>
      state.players.find((p) => p.id === id),
    getPlayersGroupByFirstRoundGroupId: (state) => {
      const groups: { [key: string]: Player[] } = {};
      state.players.forEach((player) => {
        if (player.firstRoundGroupId) {
          if (!groups[player.firstRoundGroupId]) {
            groups[player.firstRoundGroupId] = [];
          }
          groups[player.firstRoundGroupId].push(player);
        }
      });
      return groups;
    },
    getPlayersGroupByKnockoutRoundGroupId: (state) => {
      const groups: { [key: string]: Player[] } = {};
      state.players.forEach((player) => {
        if (player.knockoutRoundGroupId) {
          if (!groups[player.knockoutRoundGroupId]) {
            groups[player.knockoutRoundGroupId] = [];
          }
          groups[player.knockoutRoundGroupId].push(player);
        }
      });
      return groups;
    },
    getPlayerMappingByPlayerId: (state) => {
      const mapping: { [key: string]: Player } = {};
      state.players.forEach((player) => {
        if (player.id) {
          mapping[player.id] = player;
        }
      });
      return mapping;
    },
  },
  reducers: {
    setPlayers(state, action: PayloadAction<Player[]>) {
      state.players = action.payload;
    },
    addPlayer(state, action: PayloadAction<Player>) {
      state.players.push(action.payload);
    },
    updatePlayer(state, action: PayloadAction<Player>) {
      const index = state.players.findIndex((p) => p.id === action.payload.id);
      if (index !== -1) {
        state.players[index] = action.payload;
      }
    },
    removePlayer(state, action: PayloadAction<string>) {
      state.players = state.players.filter((p) => p.id !== action.payload);
    },
    clearPlayers: (state) => {
      state.players = [];
    },
  },
  extraReducers: (builder) => {
    // Fetch Players
    builder.addCase(fetchPlayers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPlayers.fulfilled,
      (state, action: PayloadAction<Player[]>) => {
        state.loading = false;
        state.players = action.payload;
      }
    );
    builder.addCase(fetchPlayers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default playerSlice.reducer;

export const { clearPlayers, addPlayer, updatePlayer, removePlayer } =
  playerSlice.actions;

export const {
  getPlayers,
  getPlayerById,
  getPlayersGroupByFirstRoundGroupId,
  getPlayersGroupByKnockoutRoundGroupId,
  getPlayerMappingByPlayerId,
} = playerSlice.selectors;
