import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import playersReducer from "./playerSlice";
import groupsReducer from "./groupSlice";
import betStatsReducer from "./betStatsSlice";

const store = configureStore({
  reducer: {
    // Add your slice reducers here
    players: playersReducer,
    groups: groupsReducer,
    betStats: betStatsReducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
