import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Iframe from "./Iframe";
import Prize from "./Prize";

function Final() {
  const [searchParams] = useSearchParams();
  const [indexShow, setIndexShow] = useState(0);
  const pages = useMemo(() => {
    return [
      {
        page: "iframe",
        round: 1,
        element: (
          <Iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS6fcSY4zqvewUWC9vq8F5VJ5Lvas2QJeCRkOey4JMY3hu0QX_mskVxZa4tDS0Lw2UiMVHaf1VBGudm/embed" />
        ),
      },
      { page: "prize", round: 5, element: <Prize /> },
    ];
  }, []);
  const pageSize = useMemo(() => pages.length, [pages]);
  const page = useMemo(() => get(pages, indexShow, null), [indexShow, pages]);
  const time = useMemo(
    () => parseInt(searchParams.get("second") ?? "10") * 1000,
    [searchParams]
  );

  useEffect(() => {
    if (page) {
      const timer = setTimeout(() => {
        setIndexShow((old) => (old + 1) % pageSize);
      }, time * (page.round ?? 0));
      return () => {
        clearTimeout(timer);
      };
    }
  }, [time, page, pageSize]);

  return page?.element ?? null;
}

export default Final;
