// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwmgTG9Wlya4PA-a2CuExr8FHuVfjjzxY",
  authDomain: "b-world-cup.firebaseapp.com",
  projectId: "b-world-cup",
  storageBucket: "b-world-cup.firebasestorage.app",
  messagingSenderId: "98319197930",
  appId: "1:98319197930:web:087d574c1bb28fbbf35993",
  measurementId: "G-FDQR24FSV1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
