import React, { useCallback, useEffect, useMemo } from "react";
import { chunk, get, map } from "lodash";
import Table from "./Table";
import { useSelector } from "react-redux";
import { selectStatGroupByPlayerByRound } from "../store/betStatsSlice";
import { selectGroupByRound } from "../store/groupSlice";
import { getPlayerMappingByPlayerId } from "../store/playerSlice";
import { makeFixturesData } from "../utils";
import { useSearchParams } from "react-router-dom";

function Fixtures2() {
  const [searchParams] = useSearchParams();
  const groupsFirst = useSelector((state: any) =>
    selectGroupByRound(state, "first")
  );
  const playersMapping = useSelector(getPlayerMappingByPlayerId);
  const statByPlayer = useSelector((state: any) =>
    selectStatGroupByPlayerByRound(state, "first")
  );

  const cols = useMemo(() => {
    return [
      {
        key: "name",
        col: 4,
        format: (val: string | number) => (
          <div className="d-flex gap-2 align-items-center">
            <img
              alt="profile"
              src="/assets/profile-user.svg"
              width={32}
              height={32}
              loading="eager"
            />
            <div>{val}</div>
          </div>
        ),
      },
      {
        key: "shoe",
        col: 1,
        className: "justify-content-center",
      },
      {
        key: "point",
        col: 2,
        className: "justify-content-end",
      },
      {
        key: "chip",
        col: 4,
        className: "justify-content-end px-3",
        format: (val: string | number) =>
          val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
    ];
  }, []);
  const groups = useMemo(() => {
    return makeFixturesData(groupsFirst, playersMapping, statByPlayer);
  }, [groupsFirst, playersMapping, statByPlayer]);

  const titles = useMemo(() => {
    return {
      name: "Competitor",
      shoe: "แข่ง",
      point: "Point",
      chip: "ชิปสะสม",
    };
  }, []);
  const changeShowGroup = useCallback(() => {
    const groupTableEle = document.getElementById("group-table");
    if (groupTableEle) {
      const groupShow = groupTableEle.querySelector("&> div:not(.d-none)");
      const indexShow = Array.prototype.indexOf.call(
        groupTableEle.children,
        groupShow
      );
      groupShow?.classList.toggle("d-none", true);
      setTimeout(() => {
        get(
          groupTableEle.children,
          (indexShow + 1) % groupTableEle.childElementCount
        )?.classList.toggle("d-none", false);
      }, 200);
    }
  }, []);
  useEffect(() => {
    const timer = setInterval(
      changeShowGroup,
      parseInt(searchParams.get("second") ?? "10") * 1000
    );
    return () => {
      clearInterval(timer);
    };
  }, [changeShowGroup, searchParams]);
  return (
    <div className="position-relative mx-4 py-3">
      <div className="position-absolute start-0 bottom-100 page-type">
        FIXTURES
      </div>
      <div className="round-status">Group Stage</div>
      <div id="group-table" className="mt-3">
        {map(chunk(groups, 2), (groupsChunk, chunkIndex) => (
          <div
            key={chunkIndex}
            className={`row row-cols-2 g-4 mt-0 border-between ${
              chunkIndex ? "d-none" : ""
            }`}
            style={{ fontSize: "2rem" }}
          >
            {map(groupsChunk, (group, index) => (
              <div key={index} className="col mt-0">
                <Table
                  header={group.name}
                  items={group.users}
                  cols={cols}
                  titles={titles}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fixtures2;
