import React, { useCallback, useEffect, useMemo } from "react";
import { get, map } from "lodash";
import Table from "./Table";
import { useSelector } from "react-redux";
import { selectStatGroupByPlayer } from "../store/betStatsSlice";
import { getPlayers } from "../store/playerSlice";
import { useSearchParams } from "react-router-dom";
import { UserBetStatByShoe } from "../types";

function Prize() {
  const players = useSelector(getPlayers);
  const statByPlayer = useSelector(selectStatGroupByPlayer);

  const [searchParams] = useSearchParams();
  const cols = useMemo(() => {
    return [
      {
        key: "name",
        col: 4,
        format: (val: string | number) => (
          <div className="d-flex gap-2 align-items-center">
            <img
              alt="profile"
              src="/assets/profile-user.svg"
              width={32}
              height={32}
              loading="eager"
            />
            <div>{val}</div>
          </div>
        ),
      },
      {
        key: "value",
        col: 5,
        className: "justify-content-end",
        format: (val: string | number) =>
          val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
    ];
  }, []);
  const users = useMemo(() => {
    return map(players, (player) => {
      if (!player.id) {
        return {
          name: "Unknown",
          chip: 0,
        };
      }
      const stats = statByPlayer[player.id];
      const chip = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return acc + stat.totalBalance;
          }, 0)
        : 0;
      const shoe = stats ? stats.length : 0;
      const times = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return acc + stat.totalBet;
          }, 0)
        : 0;
      const maxWinStackTimes = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return Math.max(acc, stat.maxWinStackTimes);
          }, 0)
        : 0;

      const maxWinAmount = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return Math.max(acc, stat.maxWinAmount);
          }, 0)
        : 0;

      const lastGameWinAmount = stats
        ? stats.reduce((acc: number, stat: UserBetStatByShoe) => {
            return Math.max(acc, stat.lastGameWinAmount);
          }, 0)
        : 0;

      return {
        name: player.nickname,
        chip,
        shoe,
        times,
        maxWinStackTimes,
        maxWinAmount,
        lastGameWinAmount,
      };
    });
  }, [players, statByPlayer]);

  const prizes = useMemo(() => {
    const mostBets = users
      .map((user) => ({ name: user.name, value: user.times }))
      .filter((a: any) => a.value > 0)
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 10);
    const perfectRound = users
      .map((user) => ({ name: user.name, value: user.maxWinStackTimes }))
      .filter((a: any) => a.value > 0)
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 10);
    const biggestBet = users
      .map((user) => ({ name: user.name, value: user.maxWinAmount }))
      .filter((a: any) => a.value > 0)
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 10);
    const mostChips = users
      .map((user) => ({ name: user.name, value: user.chip }))
      .filter((a: any) => a.value > 0)
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 10);
    const lastChance = users
      .map((user) => ({ name: user.name, value: user.lastGameWinAmount }))
      .filter((a: any) => a.value > 0)
      .sort((a: any, b: any) => b.value - a.value)
      .slice(0, 10);

    return [
      {
        key: "most-bets",
        title: "Most Bets Placed",
        description: "รางวัลผู้เล่นที่วางเดิมพันจำนวนตามากที่สุด",
        amount: 100000,
        valueTitle: "ยอดวางเดิมพัน",
        users: mostBets,
      },
      {
        key: "perfect-round",
        title: "Perfect Round",
        description: "รางวัลจำนวนเดิมพันชนะต่อเนื่องมากที่สุดต่อเกม",
        amount: 100000,
        valueTitle: "จำนวนตาที่ชนะต่อเกม",
        users: perfectRound,
      },
      {
        key: "biggest-bet",
        title: "Biggest Bet Winner",
        description: "รางวัลชนะเดิมพันสูงที่สุด",
        amount: 100000,
        valueTitle: "ยอดชนะเดิมพัน",
        users: biggestBet,
      },
      {
        key: "most-chips",
        title: "The Most Chip's Player",
        description: "รางวัลผู้เล่นที่สะสมชิปมากที่สุด",
        amount: 200000,
        valueTitle: "จำนวนชิปสะสม",
        users: mostChips,
      },
      {
        key: "last-chance",
        title: "Last Chance Hero",
        description: "รางวัลผู้ชนะที่วางเดิมพันมากที่สุดในตาสุดท้าย",
        amount: 100000,
        valueTitle: "ยอดเดิมพันตาสุดท้าย",
        users: lastChance,
      },
    ];
  }, [users]);

  const changeShowGroup = useCallback(() => {
    const groupTableEle = document.getElementById("group-table");
    if (groupTableEle) {
      const groupShow = groupTableEle.querySelector("&> div:not(.d-none)");
      const indexShow = Array.prototype.indexOf.call(
        groupTableEle.children,
        groupShow
      );
      groupShow?.classList.toggle("d-none", true);
      setTimeout(() => {
        get(
          groupTableEle.children,
          (indexShow + 1) % groupTableEle.childElementCount
        )?.classList.toggle("d-none", false);
      }, 200);
    }
  }, []);
  useEffect(() => {
    const prizeParam = searchParams.get("type");
    document
      .getElementById(`prize-${prizeParam ?? "most-bets"}`)
      ?.classList.toggle("d-none", false);
    if (!prizeParam) {
      const timer = setInterval(
        changeShowGroup,
        parseInt(searchParams.get("second") ?? "10") * 1000
      );
      return () => {
        clearInterval(timer);
      };
    }
  }, [changeShowGroup, searchParams]);
  return (
    <div className="position-relative mx-4 py-3">
      <div id="group-table" className="mt-3" style={{ fontSize: "1.75rem" }}>
        {map(prizes, (prize, index) => (
          <div
            key={prize.key}
            id={`prize-${prize.key}`}
            className={"border-between d-none"}
            style={{ display: "flex" }}
          >
            <div className="d-flex flex-column gap-3 text-center align-items-center my-5 pe-3 me-3">
              <img
                alt={prize.key}
                src={`/assets/${prize.key}.png?v=1`}
                width="auto"
                height={400}
              />
              <div>
                <div className="fw-bold">{prize.title}</div>
                <div className="fw-thin mt-2" style={{ fontSize: "1.5rem" }}>
                  {prize.description}
                </div>
                <div
                  className="fw-bold text-gold"
                  style={{ fontSize: "2.5rem" }}
                >
                  {`รางวัลมูลค่า ฿ `}
                  {prize.amount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </div>
            </div>
            <Table
              rankCol={2}
              header={
                <div className={`row g-0 text-center w-100`}>
                  <div key="rank" className="col-2 justify-content-center">
                    ลำดับ
                  </div>
                  <div className="col-4">ผู้เข้าแข่งขัน</div>
                  <div className="col-5 justify-content-end">
                    {prize.valueTitle}
                  </div>
                </div>
              }
              items={prize.users}
              cols={cols}
              classNameHeader="px-0 py-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Prize;
