import { useSearchParams } from "react-router-dom";

function Iframe(props: { src: string }) {
  const [searchParams] = useSearchParams();
  return (
    <div className="position-fixed top-0 start-0 end-0 bottom-0">
      <iframe
        title="slide"
        src={`${props.src}?start=true&loop=true&delayms=${
          searchParams.get("second") ?? "10"
        }000`}
        frameBorder="0"
        width="1600"
        height="936"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Iframe;
