import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Fixtures10 from "./components/Fixtures-10";
import Fixtures2 from "./components/Fixtures-2";
import Prize from "./components/Prize";
import All from "./components/All";
import Before from "./components/Before";
import GroupStage from "./components/Group-stage";
import Knockout1 from "./components/Knockout-1";
import Knockout2 from "./components/Knockout-2";
import Final from "./components/Final";
import After from "./components/After";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { subscribeToPlayers, clearPlayers } from "./store/playerSlice";
import {
  subscribeToGroups,
  clearState as clearStateGroup,
} from "./store/groupSlice";
import {
  subscribeToUserBetStat,
  clearState as clearStateStat,
} from "./store/betStatsSlice";
import { AppDispatch } from "./store";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const unsubscribeFromGroups = dispatch(subscribeToGroups());
    const unsubscribeFromPlayers = dispatch(subscribeToPlayers());
    const unsubscribeFromUserBetStat = dispatch(subscribeToUserBetStat());

    return () => {
      unsubscribeFromGroups();
      unsubscribeFromPlayers();
      unsubscribeFromUserBetStat();

      dispatch(clearStateGroup());
      dispatch(clearPlayers());
      dispatch(clearStateStat());
    };
  }, [dispatch]);

  const onResize = useCallback(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.transform = `scale(${document.body.clientWidth / 1600})`;
    }
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <img
          className="mt-4 ms-3"
          src="/assets/logo.png"
          width={356}
          height={170}
          alt="logo"
        />
        <div className="mt-4 me-3">
          <img src="/assets/banner.png" width="auto" height={170} alt="logo" />
          <div className="event-date lh-1">20 - 22 december 2024</div>
        </div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/before" element={<Before />} />
          <Route path="/group-stage" element={<GroupStage />} />
          <Route path="/knockout-1" element={<Knockout1 />} />
          <Route path="/knockout-2" element={<Knockout2 />} />
          <Route path="/final" element={<Final />} />
          <Route path="/after" element={<After />} />
          <Route path="/fixtures-10" element={<Fixtures10 />} />
          <Route path="/fixtures-2" element={<Fixtures2 />} />
          <Route path="/prize" element={<Prize />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
